@import 'assets/less/variables';

.product-addons-table {
  & .ant-table-content {
    overflow: initial !important;
  }

  &__actions {
    display: flex;
    justify-content: center;
  }

  &__menu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 2px;
    color: @gray-1;
    border: 1px solid @gray-2;
    border-radius: @border-radius;
    cursor: pointer;
  }

  &__icon {
    width: 20px;
    height: 20px;

    &--gray {
      rect,
      path {
        fill: @gray-1;
      }
    }
  }

  &__title,
  &__colored {
    color: @primary;
  }

  &__colored {
    font-weight: 500;
  }
}

@primary-color: #506efa;@success-color: #04aa77;@error-color: #ee2d41;@highlight-color: #ee2d41;@warning-color: #ffa800;@layout-body-background: #f8f8f8;@border-radius-base: 10px;@border-color-base: #d6d6e0;@text-color: #212121;@font-size-base: 14px;@font-size-lg: @font-size-base;@form-vertical-label-padding: 0 0 2px;@btn-default-color: #80808f;@btn-danger-color: @error-color;@btn-danger-bg: #ffe1e1;@table-header-bg: #f8f8f8;@table-row-hover-bg: inherit;@table-body-sort-bg: inherit;@modal-header-border-style: none;@modal-footer-border-style: none;@modal-header-padding: 20px 20px 0 20px;@modal-body-padding: 0 20px;@modal-footer-padding-vertical: 0 20px;@modal-footer-padding-horizontal: 20px;@font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
         sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';