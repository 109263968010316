@import 'assets/less/variables';

.alert-success {
  display: flex;
  padding: 10px 16px;
  border-radius: @border-radius;
  background-color: rgba(@success, 0.1);

  .title {
    margin-bottom: 0;
    font-size: 14px;
    color: @success;
  }

  &__icon {
    height: 18px;
    margin-right: 12px;

    path {
      fill: @success;
    }
  }

  &__subtitle {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 400;
    color: @dark;
    opacity: 0.5;
  }

  &__description {
    font-size: 13px;
    color: @dark;
  }

  &__action.ant-btn {
    padding: 0;
    font-weight: 500;
    color: @success;

    &:hover,
    &:focus,
    &:active {
      color: rgba(@success, 0.8);
    }
  }
}

@primary-color: #506efa;@success-color: #04aa77;@error-color: #ee2d41;@highlight-color: #ee2d41;@warning-color: #ffa800;@layout-body-background: #f8f8f8;@border-radius-base: 10px;@border-color-base: #d6d6e0;@text-color: #212121;@font-size-base: 14px;@font-size-lg: @font-size-base;@form-vertical-label-padding: 0 0 2px;@btn-default-color: #80808f;@btn-danger-color: @error-color;@btn-danger-bg: #ffe1e1;@table-header-bg: #f8f8f8;@table-row-hover-bg: inherit;@table-body-sort-bg: inherit;@modal-header-border-style: none;@modal-footer-border-style: none;@modal-header-padding: 20px 20px 0 20px;@modal-body-padding: 0 20px;@modal-footer-padding-vertical: 0 20px;@modal-footer-padding-horizontal: 20px;@font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
         sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';