@import './variables.less';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Manrope:wght@200;300;400;500;600;700&display=swap');

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set html/body/root/app height */
html,
body,
#root,
.App {
  height: auto;
  min-height: 100vh;
}

/* Set core body defaults */
body {
  color: @dark;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

/* Remove default list styles */
ul[class],
ol[class] {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  font-weight: 500;
}

/* Life is too short for that weird gap you get at the bottom */
img {
  display: block;
  max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
  outline: none;
}

textarea {
  resize: none;
}

button {
  width: auto;
  padding: 0;
  overflow: visible;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  user-select: none;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}

.no-wrap {
  white-space: nowrap;
}

.ant-layout {
  height: 100%;
}

.ant-checkbox-inner {
  border-color: @primary;
  border-radius: 4px;
}

.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10%;
  background: @primary;
}

::-webkit-scrollbar-track {
  background: @gray-2;
}

.ant-input-affix-wrapper:hover {
  z-index: 0;
}

.ant-spin.ant-spin-spinning {
  & .preloader {
    width: 80px;
    height: 80px;
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: none;
}

.input-field-colored {
  .colored-styles {
    border-color: @light;
    background-color: @primary-medium;
  }

  label {
    color: @light;
  }

  input.ant-input {
    color: @light;
    border-color: transparent;
    background-color: @primary-medium;
    box-shadow: none;
    transition: all 0.3s ease-out;

    &::placeholder {
      color: rgba(@light, 0.4);
    }

    &:focus,
    &:hover {
      color: @light;
      background-color: @primary-medium;
      box-shadow: none;
    }

    &:hover {
      border-color: rgba(@light, 0.4);
    }

    &:focus {
      border-color: @light;
    }
  }

  // Checkbox styles
  &-checkbox {
    .ant-checkbox-inner,
    .ant-checkbox-checked .ant-checkbox-inner {
      &:extend(.input-field-colored .colored-styles);
    }

    .ant-checkbox-checked::after {
      border: none;
    }

    label:hover {
      .ant-checkbox-inner {
        &:extend(.input-field-colored .colored-styles);
      }
    }

    input {
      &:hover,
      &:focus {
        + .ant-checkbox-inner {
          &:extend(.input-field-colored .colored-styles);
        }
      }
    }
  }

  // Error state
  &.ant-form-item-has-error {
    .ant-form-item-control-input {
      &::after {
        content: url('../../../icons/errorInput.svg');
        position: absolute;
        right: 8px;
        z-index: 1;
        width: 14px;
        height: 14px;
      }
    }

    input {
      padding-right: 20px;
    }

    &.ant-form-item {
      margin-bottom: 32px;
    }
  }

  .ant-form-item-explain.ant-form-item-explain-success {
    display: none;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    position: absolute;
    top: 32px;
    right: 0;
    z-index: 1;

    > * {
      padding: 8px 8px 4px;
      font-size: 13px;
      line-height: 18px;
      color: @light;
      border-radius: @border-radius / 2;
      background-color: @dark;

      &::after {
        content: '';
        position: absolute;
        top: -3px;
        right: 10px;
        z-index: 1;
        width: 0;
        height: 0;
        border-right: 5px solid transparent;
        border-bottom: 5px solid @error;
        border-left: 5px solid transparent;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 5px;
        border-top-left-radius: @border-radius / 2;
        border-top-right-radius: @border-radius / 2;
        background-color: @error;
      }
    }
  }
}

// Remove text shadow for buttons in modals
.ant-modal {
  button.ant-btn {
    text-shadow: none;
  }
}

.ant-tooltip-arrow {
  width: 16px;
  height: 16px;
}

.ant-btn.ant-btn-primary, .ant-btn.ant-btn-default  {
  box-shadow: none;
  border-radius: 10px;
}

.ant-btn-primary {
  background-color: @primary;
  color: @light;

}

.ant-btn.ant-btn-primary:hover {
  background-color: @primary-medium;
}

.ant-btn-primary.ant-btn-dangerous {
  background-color: @error-light;
  color: @error;
}

.ant-btn-primary.ant-btn-background-ghost {
  border: 1px solid @primary;
  color: @primary;
}

.ant-btn.ant-btn-primary.ant-btn-background-ghost:hover {
  border: 1px solid @primary-medium;
  color: @primary-medium;
}

.ant-btn.ant-btn-default:hover {
  border: 1px solid @primary-medium;
  color: @primary-medium;
}