@import 'assets/less/variables';

.statistic-numbers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__regrets-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  &__regrets-titles {
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    color: @gray-1;
    span {
      font-weight: 600;
      color: @dark;
    }
    &:nth-child(2) {
      &::after {
        content: '';
        display: inline-block;
        width: 2px;
        height: 2px;
        margin: 0 6px 3px 6px;
        -moz-border-radius: 1px;
        -webkit-border-radius: 1px;
        border-radius: 1px;
        background-color: @gray-1;
      }
      &::before {
        content: '';
        display: inline-block;
        width: 2px;
        height: 2px;
        margin: 0 6px 3px 6px;
        -moz-border-radius: 1px;
        -webkit-border-radius: 1px;
        border-radius: 1px;
        background-color: @gray-1;
      }
    }
  }

  &__colored-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  &__item {
    display: flex;
    line-height: 18px;
    color: @gray-1;

    &:not(:last-child) {
      margin-right: 24px;
    }

    &::before {
      content: '';
      display: block;
      flex-shrink: 0;
      width: 4px;
      height: 18px;
      margin-right: 10px;
    }

    &--green::before {
      background: @color-1;
    }

    &--yellow::before {
      background: @color-2;
    }

    &--red::before {
      background: @secondary;
    }

    &--violet::before {
      background: @color-3;
    }

    &--gray::before {
      background: @gray-2;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  &__title {
    margin-right: 5px;
    font-weight: 600;
    color: @dark;
  }

  &__info {
    font-weight: 600;
  }

  &__value {
    margin-right: 4px;
    color: @dark;
  }

  &__percent {
    color: #a4a4a5 !important;
  }

  &__cost-value {
    margin-left: 5px;
    color: @dark;
  }

  &__regretted-container {
    padding: 8px 8px 15px 20px;
    border-radius: @border-radius;
    background: @gray-3;
  }

  &__regretted {
    display: flex;
    margin-bottom: 5px;
    font-weight: 500;
    color: @gray-1;
  }

  &__regretted-value {
    margin: 0 4px 0 auto;
    color: @dark;
  }

  &__regretted-row {
    display: flex;
  }
}

@primary-color: #506efa;@success-color: #04aa77;@error-color: #ee2d41;@highlight-color: #ee2d41;@warning-color: #ffa800;@layout-body-background: #f8f8f8;@border-radius-base: 10px;@border-color-base: #d6d6e0;@text-color: #212121;@font-size-base: 14px;@font-size-lg: @font-size-base;@form-vertical-label-padding: 0 0 2px;@btn-default-color: #80808f;@btn-danger-color: @error-color;@btn-danger-bg: #ffe1e1;@table-header-bg: #f8f8f8;@table-row-hover-bg: inherit;@table-body-sort-bg: inherit;@modal-header-border-style: none;@modal-footer-border-style: none;@modal-header-padding: 20px 20px 0 20px;@modal-body-padding: 0 20px;@modal-footer-padding-vertical: 0 20px;@modal-footer-padding-horizontal: 20px;@font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
         sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';