@import 'assets/less/variables';

.notes {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 0;

  & .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 10px;
  }

  &__preloader {
    height: 300px;
  }

  &__stub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 0;
    padding: 20px 0;
  }

  &__caption {
    color: @gray-2;
  }

  &__items {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    max-height: 300px;
    margin-bottom: 10px;
    overflow-y: auto;
  }

  &__infinite-scroll {
    display: flex;
    flex-direction: column-reverse;
  }

  &__item {
    margin-bottom: 15px;
    padding-right: 15px;
    white-space: pre-line;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  &__author {
    font-weight: 500;
  }

  &__time {
    font-size: 12px;
    color: @gray-1;
  }

  &__content {
    word-wrap: break-word;
    color: @gray-1;
  }

  &__form {
    display: flex;
  }

  &__input {
    width: 100%;

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-track-piece:end {
      margin-bottom: 10px;
      background: @gray-2;
    }

    ::-webkit-scrollbar-track-piece:start {
      margin-top: 10px;
      background: @gray-2;
    }
  }

  & &__send {
    width: 48px;
    margin-left: 10px;
    padding: 2px 0 0 2px;
    border: none;
  }
}

@primary-color: #506efa;@success-color: #04aa77;@error-color: #ee2d41;@highlight-color: #ee2d41;@warning-color: #ffa800;@layout-body-background: #f8f8f8;@border-radius-base: 10px;@border-color-base: #d6d6e0;@text-color: #212121;@font-size-base: 14px;@font-size-lg: @font-size-base;@form-vertical-label-padding: 0 0 2px;@btn-default-color: #80808f;@btn-danger-color: @error-color;@btn-danger-bg: #ffe1e1;@table-header-bg: #f8f8f8;@table-row-hover-bg: inherit;@table-body-sort-bg: inherit;@modal-header-border-style: none;@modal-footer-border-style: none;@modal-header-padding: 20px 20px 0 20px;@modal-body-padding: 0 20px;@modal-footer-padding-vertical: 0 20px;@modal-footer-padding-horizontal: 20px;@font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
         sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';