@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bebasneue/v14/JTUSjIg69CK48gW7PXooxW4.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE-_F.ttf) format('truetype');
}
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}
/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}
/* Set html/body/root/app height */
html,
body,
#root,
.App {
  height: auto;
  min-height: 100vh;
}
/* Set core body defaults */
body {
  color: #212121;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}
/* Remove default list styles */
ul[class],
ol[class] {
  margin: 0;
  padding: 0;
  list-style: none;
}
/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  font-weight: 500;
}
/* Life is too short for that weird gap you get at the bottom */
img {
  display: block;
  max-width: 100%;
}
/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
  outline: none;
}
textarea {
  resize: none;
}
button {
  width: auto;
  padding: 0;
  overflow: visible;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  user-select: none;
}
@media (prefers-reduced-motion: reduce) {
  * {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}
.no-wrap {
  white-space: nowrap;
}
.ant-layout {
  height: 100%;
}
.ant-checkbox-inner {
  border-color: #506efa;
  border-radius: 4px;
}
.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10%;
  background: #506efa;
}
::-webkit-scrollbar-track {
  background: #d6d6e0;
}
.ant-input-affix-wrapper:hover {
  z-index: 0;
}
.ant-spin.ant-spin-spinning .preloader {
  width: 80px;
  height: 80px;
}
.ant-spin-nested-loading > div > .ant-spin {
  max-height: none;
}
.input-field-colored .colored-styles,
.input-field-colored-checkbox .ant-checkbox-inner,
.input-field-colored-checkbox .ant-checkbox-checked .ant-checkbox-inner,
.input-field-colored-checkbox label:hover .ant-checkbox-inner,
.input-field-colored-checkbox input:hover + .ant-checkbox-inner,
.input-field-colored-checkbox input:focus + .ant-checkbox-inner {
  border-color: #ffffff;
  background-color: #627eff;
}
.input-field-colored label {
  color: #ffffff;
}
.input-field-colored input.ant-input {
  color: #ffffff;
  border-color: transparent;
  background-color: #627eff;
  box-shadow: none;
  transition: all 0.3s ease-out;
}
.input-field-colored input.ant-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.input-field-colored input.ant-input:focus,
.input-field-colored input.ant-input:hover {
  color: #ffffff;
  background-color: #627eff;
  box-shadow: none;
}
.input-field-colored input.ant-input:hover {
  border-color: rgba(255, 255, 255, 0.4);
}
.input-field-colored input.ant-input:focus {
  border-color: #ffffff;
}
.input-field-colored-checkbox .ant-checkbox-checked::after {
  border: none;
}
.input-field-colored.ant-form-item-has-error .ant-form-item-control-input::after {
  content: url('../icons/errorInput.svg');
  position: absolute;
  right: 8px;
  z-index: 1;
  width: 14px;
  height: 14px;
}
.input-field-colored.ant-form-item-has-error input {
  padding-right: 20px;
}
.input-field-colored.ant-form-item-has-error.ant-form-item {
  margin-bottom: 32px;
}
.input-field-colored .ant-form-item-explain.ant-form-item-explain-success {
  display: none;
}
.input-field-colored .ant-form-item-explain.ant-form-item-explain-error {
  position: absolute;
  top: 32px;
  right: 0;
  z-index: 1;
}
.input-field-colored .ant-form-item-explain.ant-form-item-explain-error > * {
  padding: 8px 8px 4px;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  border-radius: 10px / 2;
  background-color: #212121;
}
.input-field-colored .ant-form-item-explain.ant-form-item-explain-error > *::after {
  content: '';
  position: absolute;
  top: -3px;
  right: 10px;
  z-index: 1;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ee2d41;
  border-left: 5px solid transparent;
}
.input-field-colored .ant-form-item-explain.ant-form-item-explain-error > *::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-top-left-radius: 10px / 2;
  border-top-right-radius: 10px / 2;
  background-color: #ee2d41;
}
.ant-modal button.ant-btn {
  text-shadow: none;
}
.ant-tooltip-arrow {
  width: 16px;
  height: 16px;
}
.ant-btn.ant-btn-primary,
.ant-btn.ant-btn-default {
  box-shadow: none;
  border-radius: 10px;
}
.ant-btn-primary {
  background-color: #506efa;
  color: #ffffff;
}
.ant-btn.ant-btn-primary:hover {
  background-color: #627eff;
}
.ant-btn-primary.ant-btn-dangerous {
  background-color: #ffe1e1;
  color: #ee2d41;
}
.ant-btn-primary.ant-btn-background-ghost {
  border: 1px solid #506efa;
  color: #506efa;
}
.ant-btn.ant-btn-primary.ant-btn-background-ghost:hover {
  border: 1px solid #627eff;
  color: #627eff;
}
.ant-btn.ant-btn-default:hover {
  border: 1px solid #627eff;
  color: #627eff;
}
