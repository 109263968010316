@import 'assets/less/variables';

.radio-group-hover-focus-state {
  color: @light;
  background: rgba(0, 0, 0, 0.1);
}

.radio-group {
  display: flex;
  flex-direction: column;
  &__icon-check {
    margin-right: 5px;
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .ant-radio-button-wrapper {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: max-content;
      margin-bottom: 5px;
      padding: 5px 10px;
      border: none;
      border-radius: @border-radius;
      background: rgba(255, 255, 255, 0.2);

      &:hover,
      &:focus {
        &:extend(.radio-group-hover-focus-state);
      }

      &.ant-radio-button-wrapper-checked {
        background: rgba(0, 0, 0, 0.1);

        &:hover,
        &:focus {
          &:extend(.radio-group-hover-focus-state);
        }
      }

      &:not(:first-child)::before {
        display: none;
      }
    }
  }
}

.error-message-wraper{
  background-color: @dark;
  padding: 5px;
  border-radius: 5px;
  border-top: 5px solid rgb(220, 66, 72);
  color: @light;
  position:relative;
  margin-top: 2px;
}

.error-message-wraper:after {
  content: '';
  position: absolute;
  right: 8px;
  top: -10px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgb(220, 66, 72);
  clear: both;
}

@primary-color: #506efa;@success-color: #04aa77;@error-color: #ee2d41;@highlight-color: #ee2d41;@warning-color: #ffa800;@layout-body-background: #f8f8f8;@border-radius-base: 10px;@border-color-base: #d6d6e0;@text-color: #212121;@font-size-base: 14px;@font-size-lg: @font-size-base;@form-vertical-label-padding: 0 0 2px;@btn-default-color: #80808f;@btn-danger-color: @error-color;@btn-danger-bg: #ffe1e1;@table-header-bg: #f8f8f8;@table-row-hover-bg: inherit;@table-body-sort-bg: inherit;@modal-header-border-style: none;@modal-footer-border-style: none;@modal-header-padding: 20px 20px 0 20px;@modal-body-padding: 0 20px;@modal-footer-padding-vertical: 0 20px;@modal-footer-padding-horizontal: 20px;@font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
         sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';