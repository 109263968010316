@import 'assets/less/variables';

.search-with-params {
  z-index: 2;

  &.ant-input-group.ant-input-group-compact {
    display: flex;
  }

  & &__select {
    min-width: 100px;
    height: 40px;
  }

  & &__input {
    width: 190px;
    height: 40px;
  }

  & &__icon {
    margin-right: -5px;
    padding: 4px;
    color: @primary;
    border-radius: 5px;
    background: @primary-light;
  }

  .ant-select-selector {
    height: 40px !important;
  }

  .ant-select-selection-item {
    height: 40px !important;
    display: flex;
    align-items: center;
  }
}

@primary-color: #506efa;@success-color: #04aa77;@error-color: #ee2d41;@highlight-color: #ee2d41;@warning-color: #ffa800;@layout-body-background: #f8f8f8;@border-radius-base: 10px;@border-color-base: #d6d6e0;@text-color: #212121;@font-size-base: 14px;@font-size-lg: @font-size-base;@form-vertical-label-padding: 0 0 2px;@btn-default-color: #80808f;@btn-danger-color: @error-color;@btn-danger-bg: #ffe1e1;@table-header-bg: #f8f8f8;@table-row-hover-bg: inherit;@table-body-sort-bg: inherit;@modal-header-border-style: none;@modal-footer-border-style: none;@modal-header-padding: 20px 20px 0 20px;@modal-body-padding: 0 20px;@modal-footer-padding-vertical: 0 20px;@modal-footer-padding-horizontal: 20px;@font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
         sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';