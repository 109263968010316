@import 'assets/less/variables';

.table-card {
  .ant-select-customize-input{
    border-radius: 4px !important;
  }
  .ant-select-selector{
    border-radius: 4px !important;
  }
  .ant-select{
    border-radius: 4px !important;
  }
  &__table {
    table tr:nth-child(odd) {
      background: @gray-6;
    }

    table tr > th {
      font-weight: 700;
    }

    &--pointer table tr > td {
      cursor: pointer;
    }

    table tr > th,
    table tr > td {
      border-bottom: none;
    }

    table tr > th:first-child,
    table tr > td:first-child {
      border-top-left-radius: @border-radius;
      border-bottom-left-radius: @border-radius;
    }

    table tr > th:last-child,
    table tr > td:last-child {
      border-top-right-radius: @border-radius;
      border-bottom-right-radius: @border-radius;
    }

    .ant-table-cell{
      &::after{
        content: none !important;
      }
      &::before{
        content: none !important;
      }
    }

    .ant-table-column-sort {
      color: @primary;
    }
  }
  &__pagination-block{
    min-height: 54px;
  }

  & &__pagesizer{
    min-width: 61px;
    height: 32px;
    margin-left: 7px;
    margin-right: 7px;
  }

  & &__pagesizer-container{
    position: absolute;
    bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & &__divider {
    margin: 10px 0 20px 0;
  }

  & &__pagination {
    margin-bottom: 12px;
    text-align: right;
  }
}

@primary-color: #506efa;@success-color: #04aa77;@error-color: #ee2d41;@highlight-color: #ee2d41;@warning-color: #ffa800;@layout-body-background: #f8f8f8;@border-radius-base: 10px;@border-color-base: #d6d6e0;@text-color: #212121;@font-size-base: 14px;@font-size-lg: @font-size-base;@form-vertical-label-padding: 0 0 2px;@btn-default-color: #80808f;@btn-danger-color: @error-color;@btn-danger-bg: #ffe1e1;@table-header-bg: #f8f8f8;@table-row-hover-bg: inherit;@table-body-sort-bg: inherit;@modal-header-border-style: none;@modal-footer-border-style: none;@modal-header-padding: 20px 20px 0 20px;@modal-body-padding: 0 20px;@modal-footer-padding-vertical: 0 20px;@modal-footer-padding-horizontal: 20px;@font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
         sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';