@import 'assets/less/variables';

.navigation {
  font-family: 'Inter', sans-serif;
  
  &__group:not(:empty) {
    margin-bottom: 10px;
    padding-top: 10px;
    border-top: 1px solid @navMenu-divider;
  }

  &__group:first-of-type {
    border-top: none;
    padding-top: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 38px;
    margin-bottom: 5px;
    padding: 5px 5px 5px 13px;
    color: @gray-5;
    border-radius: @border-radius;
    transition: color 0.3s, background 0.3s, width 0.3s;
    width: 180px;

    svg rect,
    svg path {
      fill: @navMenu-light;
      transition: fill 0.3s;
    }

    &--active {
      color: @light;
      background: @primary;

      svg rect,
      svg path {
        fill: @light;
      }
    }

    &:hover {
      color: @light;
      background: @navMenu-hover;
    }

    &.collapsed {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      svg {
        margin: 0;
      }
    }
  }

  & .collapsed {
    width: 40px;

    .navigation__group {
      width: 40px;
    }
    .navigation_text {
      display: none;
    }

    &.order-link {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      svg {
        margin: 0;
      }
    }
  }

  & .order-link {
    background: @newOrder;
    border: 1px dashed @newOrder-light;
    border-radius: 6px;
    padding: 11px 0 11px 12px;
    height: 40px;

    svg {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      box-shadow: 0px 1px 4px @primary;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 24px;
    margin-right: 10px;
  }
}

@primary-color: #506efa;@success-color: #04aa77;@error-color: #ee2d41;@highlight-color: #ee2d41;@warning-color: #ffa800;@layout-body-background: #f8f8f8;@border-radius-base: 10px;@border-color-base: #d6d6e0;@text-color: #212121;@font-size-base: 14px;@font-size-lg: @font-size-base;@form-vertical-label-padding: 0 0 2px;@btn-default-color: #80808f;@btn-danger-color: @error-color;@btn-danger-bg: #ffe1e1;@table-header-bg: #f8f8f8;@table-row-hover-bg: inherit;@table-body-sort-bg: inherit;@modal-header-border-style: none;@modal-footer-border-style: none;@modal-header-padding: 20px 20px 0 20px;@modal-body-padding: 0 20px;@modal-footer-padding-vertical: 0 20px;@modal-footer-padding-horizontal: 20px;@font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
         sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';