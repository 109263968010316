.FinancialBadgeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  min-width: 80px;
  height: 26px;
  margin: 5px 5px 5px 0;
  padding: 5px 5px;
  border: 1px solid #d6d6e0;
  border-radius: 10px;
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
  }
  &__icon {
    height: 12px;
  }
  &__check-icon {
    margin-right: 5px;
  }
  &__synch-icon {
    width: 12px;
    height: 12px;
    margin-left: 3px;
  }
  img {
    max-width: 60px;
    height: auto;
  }
}
.check-icon-dissabled-white {
  margin-right: 5px;
  path {
    fill: #fff !important;
  }
}
.check-icon-dissabled-black {
  margin-right: 5px;
  path {
    fill: #000 !important;
  }
}
.Badge-active {
  border-color: #506efa;
  background-color: #506efa;
}
.Badge-inactive {
  border-color: #d6d6e0;
  background-color: #d6d6e0;
}
.Badge-inactive-white {
  border-color: #d6d6e0;
  background-color: #fff;
}

@primary-color: #506efa;@success-color: #04aa77;@error-color: #ee2d41;@highlight-color: #ee2d41;@warning-color: #ffa800;@layout-body-background: #f8f8f8;@border-radius-base: 10px;@border-color-base: #d6d6e0;@text-color: #212121;@font-size-base: 14px;@font-size-lg: @font-size-base;@form-vertical-label-padding: 0 0 2px;@btn-default-color: #80808f;@btn-danger-color: @error-color;@btn-danger-bg: #ffe1e1;@table-header-bg: #f8f8f8;@table-row-hover-bg: inherit;@table-body-sort-bg: inherit;@modal-header-border-style: none;@modal-footer-border-style: none;@modal-header-padding: 20px 20px 0 20px;@modal-body-padding: 0 20px;@modal-footer-padding-vertical: 0 20px;@modal-footer-padding-horizontal: 20px;@font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
         sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';