@import 'assets/less/variables';

.statistic-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid @gray-3;

  &__title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
  }

  &__icon {
    margin-left: 5px;
    transition: fill 0.3s;
    cursor: pointer;

    & path {
      fill: @gray-2;
      transition: fill 0.3s;
    }

    &:hover path {
      fill: @primary;
    }
  }

  &__values {
    display: flex;
    flex-direction: row;
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: right;

    &:not(:first-child) {
      margin-left: 15px;
      padding-left: 15px;
      border-left: 1px solid @gray-3;
    }
  }

  &__value-name {
    margin-right: 20px;
    font-size: 14px;
    color: @gray-1;
  }

  &__value {
    font-size: 18px;
    font-weight: 600;
    color: @dark;
  }
}

@primary-color: #506efa;@success-color: #04aa77;@error-color: #ee2d41;@highlight-color: #ee2d41;@warning-color: #ffa800;@layout-body-background: #f8f8f8;@border-radius-base: 10px;@border-color-base: #d6d6e0;@text-color: #212121;@font-size-base: 14px;@font-size-lg: @font-size-base;@form-vertical-label-padding: 0 0 2px;@btn-default-color: #80808f;@btn-danger-color: @error-color;@btn-danger-bg: #ffe1e1;@table-header-bg: #f8f8f8;@table-row-hover-bg: inherit;@table-body-sort-bg: inherit;@modal-header-border-style: none;@modal-footer-border-style: none;@modal-header-padding: 20px 20px 0 20px;@modal-body-padding: 0 20px;@modal-footer-padding-vertical: 0 20px;@modal-footer-padding-horizontal: 20px;@font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
         sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';